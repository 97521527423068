<div *ngIf="lottoItem; else noPurchase" class="container">
  <div class="d-block d-lg-flex shadow border-0 rounded overflow-hidden bg-lotto mt-lg-5 mt-3">
    <div class="item-details d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 p-3">
      <div>
        <div class="text-secondary mb-0 praktika-extended-font text-uppercase font-size-14 text-center">
          {{ 'lotto.entry.successful' | translate }}
        </div>
        <hr class="bg-white mt-4 mb-2" />
        <div class="row mt-5 mb-5">
          <div class="col-lg-1 col-2 d-flex justify-content-left">
            <img
              class="purchase-success-image shadow rounded"
              src="{{ lottoItem.ImageName | contentImage : '6' : 'lotto' }}"
              alt="{{ lottoItem.ImageName }}"
              [useDefault]="true"
              [loyaltyType]="'lotto'"
              draggable="false"
            />
          </div>
          <div class="col-lg-10 col-10">
            <div class="pl-lg-3 pl-1">
              <div class="font-size-20 mb-1 praktika-condensed-font">{{ lottoItem.Title }}</div>
              <div class="row">
                <div class="col-6 col-lg-4">
                  <div class="font-size-12 mb-0">
                    <span class="praktika-extended-font font-size-10 pr-2">{{ 'lotto.entries' | translate }}</span>
                    <span class="praktika-condensed-font font-size-16">{{ quantity | number }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column border-0 success-buttons">
        <a class="btn confirm-btn text-lowercase" [routerLink]="['/rewards/raffles']">{{
          'rewards.back.to.raffles' | translate : { fgbdefault: 'Back to Giveaways' }
        }}</a>
        <a class="btn btn-wallet back-btn text-lowercase" [routerLink]="['/rewards/wallet']">{{
          'rewards.back.to.wallet' | translate : { fgbdefault: 'Back to Wallet' }
        }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">No raffles found...</div>
</ng-template>
