import { Component, Input, OnInit } from '@angular/core';
import { MemberDetails, MemberQuery, BalanceQuery, config, MemberCard, MemberCardQuery } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { UserInfomationModalComponent } from '../user-infomation-modal/user-infomation-modal.component';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  ecash$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  screenType = ScreenType;
  @Input() isAccountPage = false;

  constructor(
    private memberQuery: MemberQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }

  openModal() {
    this.modalService.open(UserInfomationModalComponent, { centered: true });
  }
}
