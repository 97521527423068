<div class="login-title-section">
  <fgbcl-errors></fgbcl-errors>
</div>

<div class="form-group">
  <button (click)="loginTM()" class="btn text-white d-flex align-items-center justify-content-center btn-block login-button w-100">
    <span class="font-size-14 praktika-extended-font">login</span>
    <span><img src="assets/images/ticketmaster.svg" class="tm-logo pl-2" /></span>
  </button>
</div>
