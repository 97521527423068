<div class="card border-0 shadow">
  <div class="card-content px-3">
    <h3 class="mb-0 font-weight-bold text-white text-center align-items-center d-flex">
      {{ 'voucher.title' | translate }}
    </h3>
    <div>
      <span class="input-group">
        <input
          type="text"
          [(ngModel)]="voucherCode"
          placeholder="Insert Code Here"
          class="form-control text-center px-5 px-lg-0"
          (keyup.enter)="redeemVoucher()"
        />
        <div class="input-group-append">
          <button
            class="btn btn-primary icon icon-arrow-1-right text-white font-weight-bold py-0"
            type="button"
            (click)="redeemVoucher()"
            [disabled]="disableButton"
          ></button>
        </div>
      </span>
    </div>
    <div class="d-flex align-items-center ml-lg-4 voucher-result mt-1 mt-lg-0">
      <fgbcl-errors></fgbcl-errors>
      <div class="result text-center" *ngIf="redemptionResult && voucherCode && sucessfulRedemption">
        <div class="font-size-10 text-secondary">
          {{ redemptionResult }}
        </div>
      </div>
      <div class="result text-center" *ngIf="redemptionResult && !voucherCode">
        <div class="font-size-10 text-white">
          {{ redemptionResult }}
        </div>
      </div>
    </div>
  </div>
</div>
