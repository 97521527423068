<div class="container">
  <div class="row pt-5">
    <div class="col-12 col-lg-6 order-2 order-lg-1">
      <div class="text-center text-lg-left">
        <h4 class="text-secondary pt-5 text-capitalize">{{ 'not.found.title.1' | translate }}</h4>
        <h5 class="text-secondary text-capitalize pb-4">{{ 'not.found.title.2' | translate }}</h5>

        <button [routerLink]="['/']" class="btn btn-secondary mb-5 mb-lg-0 pt-1">
          <label class="font-size-14 font-weight-bold">{{ 'not.found.btn' | translate }}</label>
        </button>
      </div>
    </div>
    <div class="col-12 col-lg-4 offset-lg-2 order-1 order-lg-2">
      <div class="d-flex justify-content-center">
        <img alt="" class="nf-placeholder-image" src="assets/images/placeholders/404placeholder.svg" />
      </div>
    </div>
  </div>
</div>
