<nav class="navbar navbar-dark navbar-expand-lg d-none d-lg-flex shadow container">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate: { fgbDefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img alt="" class="h-100" aria-labelledby="homeLink" src="assets/images/club_logo_filled.svg" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container"></div>
    <div class="form-inline my-2 my-lg-0">
      <!--Notifications-->
      <ng-container *notInProductCode="productcode.NonRenewing">
        <div ngbDropdown class="position-static" (openChange)="toggleNotificationCenter($event)" focusTab>
          <button
            class="notification-btn btn px-0"
            id="notificationDropdown"
            attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
            tabindex="-1"
            ngbDropdownToggle
          >
            <span class="icon notification-bell text-white material-icons material-mobile-nav px-5">notifications</span>
            <ng-container *ngIf="unseenNotificationCount$ | async">
              <div class="count-notification">{{ unseenNotificationCount$ | async }}</div>
            </ng-container>
          </button>

          <div
            ngbDropdownMenu
            aria-labelledby="notificationDropdown"
            class="notification-dropdown shadow dropdown-menu border-0 p-0"
          >
            <div class="notification-header d-flex">
              <label class="text-black font-weight-bold font-size-14 pt-3"> Notifications</label>
            </div>
            <fgbcl-notification-hub></fgbcl-notification-hub>
          </div>
        </div>
      </ng-container>
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex align-items-center px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="name font-size-14 text-left font-weight-bold text-secondary text-uppercase">
              {{ memberDetails!.FirstName }}
            </div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-center border-0 dropdown-menu p-0 account-dropdown mt-2 bg-primary"
        >
          <ng-container *notInProductCode="productcode.NonRenewing">
            <div class="text-info">
              <a
                [routerLink]="['/account']"
                routerLinkActive="router-link-active"
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  account-btn
                  text-white
                  bg-app
                  py-0
                  px-2
                  font-size-14
                  nav-item-fixed-height
                  rounded-top
                "
                ngbDropdownItem
              >
                <div class="nav-border">
                  <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">person</span>

                  <span class="text-lowercase praktika-extended-font">Account</span>
                </div>
              </a>
            </div>

            <div class="text-white">
              <a
                [routerLink]="['/rewards/wallet']"
                routerLinkActive="router-link-active"
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  account-btn
                  text-white
                  bg-app
                  py-0
                  px-2
                  nav-item-fixed-height
                "
                ngbDropdownItem
              >
                <div class="nav-border">
                  <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">account_balance_wallet</span>
                  <div class="dropdown-link mt-1 text-left">
                    <div class="font-size-14 praktika-extended-font text-lowercase">{{ 'nav.wallet' | translate }}</div>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>

          <button
            class="logout-btn bg-app px-2 pt-3 nav-item-fixed-height rounded-bottom"
            (click)="logout()"
            ngbDropdownItem
            attr.aria-label="{{ 'nav.log.out.screenreader' | translate }}"
          >
            <div class="nav-border">
              <span class="icon material-icons material-dropdown-icon mr-2 text-white" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link">
                <div
                  class="text-lowercase praktika-extended-font py-1 font-size-14 text-white"
                  ngbDropdownItem
                  (click)="logout()"
                >
                  {{ 'nav.log.out' | translate }}
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
