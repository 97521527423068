<!--STH Members-->
<ng-container *notInProductCode="productcode.NonRenewing">
  <div class="container">
    <fgb-barcode></fgb-barcode>
    <div class="mt-lg-2 mt-4">
      <fgb-vouchers></fgb-vouchers>
    </div>
    <div class="mt-lg-5 mt-4 mb-0">
      <fgb-home-nav></fgb-home-nav>
    </div>

    <fgbcl-announcements></fgbcl-announcements>

    <ng-container *ngIf="marketplaceItems$ | async as marketplaceItems">
      <ng-container *ngIf="marketplaceItems.length">
        <div class="events-carousel mt-4">
          <h2 class="heading-title mt-0">{{ 'featured.events' | translate: { fgbdefault: 'FEATURED EVENTS' } }}</h2>
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let marketplaceItem of marketplaceItems">
              <ng-template carouselSlide [width]="306">
                <fgbcl-marketplace-list-item
                  [marketplaceItem]="marketplaceItem"
                  [marketplaceItemTypes]="[3]"
                ></fgbcl-marketplace-list-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="rewardMarketplaceItems$ | async as marketplaceItems">
      <ng-container *ngIf="marketplaceItems.length">
        <div class="events-carousel mt-4">
          <h2 class="heading-title mt-0">{{ 'featured.giveaways' | translate: { fgbdefault: 'FEATURED GIVEAWAYS' } }}</h2>
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let marketplaceItem of marketplaceItems">
              <ng-template carouselSlide [width]="306">
                <fgbcl-marketplace-list-item
                  [marketplaceItem]="marketplaceItem"
                  [marketplaceItemTypes]="[0, 1, 2]"
                ></fgbcl-marketplace-list-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="mt-4">
    <fgbcl-scorecard-carousel [icon]="'north_east'" [showAllView]="false" [version2Design]="true"></fgbcl-scorecard-carousel>
  </div>
</ng-container>

<!--Non Renewing Members-->
<ng-container *inProductCode="productcode.NonRenewing">
  <ng-container *ngIf="memberDetails$ | async as memberDetails">
    <div class="container pt-4">
      <div class="name-title">
        <div class="name">
          <span>{{ 'member.card.hello' | translate }}, </span><span>{{ memberDetails.FirstName }}</span>
        </div>
      </div>

      <a href="{{ 'non.renewing.membership.url' | translate }}" target="_blank">
        <img class="w-100 h-100 pt-3 pb-4" src="/assets/images/non-renewing/non-renewing-sales.jpg" />
      </a>

      <div class="card non-renewing-card text-center">
        <div class="non-renewing-title pb-3">
          {{ 'non.renewing.membership.title' | translate }}
        </div>

        <div class="non-renewing-desc" [innerHTML]="'non.renewing.membership.desc' | translate | markdownTranslate"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
