<ng-container
  *ngIf="
    { memberDetails: memberDetails$ | async, memberCard: memberCard$ | async, ecash: ecash$ | async } as memberInfo;
    else loadingRef
  "
>
  <div class="member-card bg-app text-white">
    <div class="member-card-title" [routerLink]="['/account']">
      <div class="name">
        <span>{{ 'member.card.hello' | translate }}, </span><span>{{ memberInfo.memberDetails.FirstName }}</span>
      </div>
      <div *ngIf="!isAccountPage" class="material-icons text-secondary">north_east</div>
    </div>
    <hr class="bg-primary mt-0" />
    <div class="member-card-body row">
      <div class="img-holder col-6 pl-0 pr-0">
        <img
          *ifScreenSize="screenType.Desktop"
          class="rounded"
          src="assets/images/member-card/{{ memberInfo.memberCard.ProductCode }}.png"
          draggable="false"
        />
        <img
          *ifScreenSize="screenType.Mobile"
          class="rounded"
          src="assets/images/mobile-member-card/{{ memberInfo.memberCard.ProductCode }}.png"
          draggable="false"
        />
      </div>
      <div class="values col-5">
        <div class="discount" [ngClass]="{ hidden: !memberInfo.memberCard.DiscountLevel }">
          <div class="title">{{ 'member.card.discount.label' | translate }}</div>
          <div class="value">{{ memberInfo.memberCard.DiscountLevel }}%</div>
        </div>
        <div class="question-mark">
          <span class="material-icons text-secondary cursor-pointer" (click)="openModal()"> help </span>
        </div>
        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.ecash'">
          <div class="ecash">
            <div class="title">{{ 'member.card.grizzbucks.label' | translate }}</div>
            <div class="value">
              {{ memberInfo.ecash | FortressCurrencyPipe : '$' }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
