<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="pt-4">
        <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"></fgbcl-terms-and-conditions>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultTemplate>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
