<h1 class="accessibility-only">Badges</h1>

<div class="container">
  <fgb-barcode></fgb-barcode>
  <div class="mt-lg-2 mt-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
  <div class="mt-lg-5 mt-4 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<!--Scorecard carousel-->
<div class="mt-0">
  <fgbcl-scorecard-carousel [icon]="'north_east'" [showAllView]="false" [version2Design]="true"></fgbcl-scorecard-carousel>
</div>

<div class="container mb-4">
  <fgbcl-badge-overview-list [designV2]="true"></fgbcl-badge-overview-list>
</div>
