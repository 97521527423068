<div *ngIf="purchasedItem; else noPurchase" class="container">
  <div class="d-block d-lg-flex shadow border-0 rounded overflow-hidden bg-marketplace mt-lg-5 mt-3">
    <!-- Details -->
    <div class="item-details d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 p-3">
      <div>
        <div class="text-secondary mb-0 font-weight-bold text-uppercase font-size-14 text-center">
          <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
            'marketplace.purchase.success' | translate
          }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Event'">{{ 'events.purchase.success' | translate }}</ng-container>
        </div>
        <hr class="mt-4 bg-white mb-2" />
        <p class="mb-0 text-white praktika-extended-font font-size-14 text-center">
          <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
            'marketplace.purchase.email.confirmation' | translate
          }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Event'">{{ 'events.purchase.confirmation' | translate }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Product'">{{
            'products.purchase.email.confirmation' | translate
          }}</ng-container>
        </p>
        <div class="row mt-5 mb-4">
          <div class="col-lg-1 col-2 d-flex justify-content-left">
            <img
              class="purchase-success-image shadow rounded"
              src="{{ purchasedItem.ItemImageURL | contentImage: '7':'marketplace' }}"
              alt="{{ purchasedItem.ImageURL }}"
              [useDefault]="true"
              loyaltyType="marketplace"
              draggable="false"
            />
          </div>
          <div class="col-lg-10 col-10">
            <div class="pl-lg-3 pl-1">
              <div class="font-size-20 mb-1 praktika-condensed-font">{{ purchasedItem.DisplayName }}</div>
              <div class="row">
                <!--Attributes-->
                <ng-container *ngFor="let attribute of attributes">
                  <div class="col-12 mt-1 mb-2">
                    <div class="font-size-12 mb-0">
                      <span class="praktika-extended-font font-size-10 pr-2">{{ attribute.AttributeName }}</span>
                      <span class="praktika-condensed-font font-size-16"> {{ attribute.ValueName }}</span>
                    </div>
                  </div>
                </ng-container>
                <!--Custom Fields-->
                <ng-container *ngFor="let customfield of customFields">
                  <div class="col-12 mb-2">
                    <div class="font-size-12 mb-0">
                      <span class="praktika-extended-font font-size-10 pr-2">{{ customfield.DisplayName }}:</span>
                      <span class="praktika-condensed-font font-size-16"> {{ customfield.Value }}</span>
                    </div>
                  </div>
                </ng-container>
                <!--Quantity-->
                <div class="col-4 col-lg-3">
                  <div class="font-size-12 mb-0">
                    <span class="praktika-extended-font font-size-10 pr-2">{{ 'marketplace.quantity' | translate }}</span>
                    <span class="praktika-condensed-font font-size-16">{{ quantity }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto mb-2 border-0" *ngIf="purchasedItem.ProcessProviderId == ProcessProvider.Download">
        <a
          class="d-flex align-items-center justify-content-center btn btn-primary confirm-btn font-weight-bold download-btn"
          (click)="downloadPurchasedItem(purchasedItemLogId)"
        >
          <span class="icon material-icons mr-1 purchased-download-icon">download_for_offline</span>
          <span class="font-size-14">{{ 'download.rewards.item' | translate }}</span>
        </a>
      </div>

      <div class="d-flex flex-column border-0 success-buttons">
        <a
          *ngIf="marketplaceItemType == 'Marketplace'"
          class="btn confirm-btn text-lowercase"
          [routerLink]="['/rewards/marketplace']"
          >{{ 'rewards.back.to.marketplace' | translate }}</a
        >
        <a *ngIf="marketplaceItemType == 'Event'" class="btn confirm-btn text-lowercase" [routerLink]="['/rewards/events']">{{
          'rewards.back.to.experience' | translate
        }}</a>
        <a class="btn btn-wallet back-btn text-lowercase" [routerLink]="['/rewards/wallet']">{{
          'rewards.back.to.wallet' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">No purchased item found...</div>
</ng-template>
