<div class="container py-3">
  <fgb-barcode [isWalletPage]="true"></fgb-barcode>

  <div class="d-lg-block d-none mt-lg-5 mt-4 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<ng-container *ngIf="walletItemsUnclaimed$ | async as walletItemsUnclaimed; else noWalletItems">
  <div class="wallet-redeem-carousel">
    <div class="container">
      <ng-container *ngIf="walletItemsUnclaimed && walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mt-5 mb-3 font-weight-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border">
      <div class="no-wallet-items-description font-weight-bold font-size-12 text-center text-white py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text text-white font-weight-bold" [routerLink]="['/rewards/claimed']"> View Claimed Rewards </a>
</div>
