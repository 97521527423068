<div class="container">
  <h1 class="title">{{ 'contact.us.page.title' | translate }}</h1>
  <div class="rounded p-lg-3 p-0">
    <div class="col-12 col-md-10 m-auto">
      <div class="contact-us-box p-3">
        <form [formGroup]="contactUsForm" (ngSubmit)="submit(contactUsForm.value, contactUsForm.valid)">
          <div class="contact-title-text mt-2 mb-1 font-size-10 praktika-extended-font">
            {{ 'contact.us.category' | translate }}
          </div>
          <div>
            <div class="dropdown">
              <select
                class="contact-us-dropdown w-100 text-left rounded p-2 bg-app text-white font-size-14 praktika-extended-font"
                formControlName="category"
                (change)="onCategoryChange()"
              >
                <option value="" selected disabled>
                  {{ 'default.option.text' | translate : { fgbdefault: 'Choose category' } }}
                </option>
                <option *ngFor="let category of categories$ | async" [value]="category.Value">
                  {{ category.Value }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="contact-title-text font-weight-bold mt-2 mb-1 font-size-10 praktika-extended-font">
              {{ 'contact.us.message' | translate }}
            </div>
            <textarea class="text-white input-group h-200 rounded bg-app" formControlName="message"></textarea>
            <div *ngIf="submitted && f.message.errors && !sentSuccessfully" class="alert alert-danger">
              <div *ngIf="f.message.errors.required">
                {{ 'contact.us.message.error' | translate }}
              </div>
              <div *ngIf="f.message.errors.whitespace">
                {{ 'contact.us.message.error' | translate }}
              </div>
            </div>
          </div>
          <button
            class="send-button font-size-14 d-block ml-auto mr-auto rounded mt-4 contact-us-box font-weight-bold text-white mb-2 border-0"
            type="submit"
            [disabled]="sentSuccessfully"
          >
            {{ 'contact.us.submit' | translate }}
          </button>
          <div class="success-message text-center" *ngIf="sentSuccessfully">
            {{ 'contact.us.submit.success' | translate }}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
