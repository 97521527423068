<!--Banner-->
<div class="d-none d-lg-block">
  <!--Mixed Loyalty Nav-->
</div>
<!--Poimt Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/raffles'" [backButtonTitle]="'Giveaways'"></fgb-points-summary-bar>
</div>
<!--Lotto Details-->
<div class="mt-lg-5 pt-lg-5">
  <fgbcl-lotto-details [lottoId]="id"></fgbcl-lotto-details>
</div>
<div class="container">
  <div class="pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="'/rewards/raffles'">
    <span class="icon material-icons text-secondary font-size-20 pr-2">west</span>
    <span class="font-size-20 text-uppercase text-white praktika-condensed-font">{{ 'back.to.giveaways' | translate }} </span>
  </div>
</div>
