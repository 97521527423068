<!-- Points Summary -->
<div class="container" *ngIf="marketplaceItem$ | async">
  <fgb-points-summary-bar
    [backButtonLink]="marketplaceItemType.backLink"
    [marketplaceItemGroup]="marketplaceItemType.group"
  ></fgb-points-summary-bar>
</div>

<div class="mt-lg-5 pt-lg-5">
  <fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>
</div>

<div class="container">
  <div class="pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="[marketplaceItemType.backLink]">
    <span class="icon material-icons text-secondary font-size-20 pr-2">west</span>
    <span *ngIf="marketplaceItemType.group == 'Events'" class="back-to-text"> {{ 'back.to.events' | translate }} </span>
    <span *ngIf="marketplaceItemType.group == 'Marketplace'" class="back-to-text"> {{ 'back.to.marketplace' | translate }} </span>
  </div>
</div>
