<nav class="nav home-nav">
  <a class="nav-item" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <label>{{ 'nav.home' | translate }} </label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/events']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <label>{{ 'nav.events' | translate }} </label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/marketplace']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <label>{{ 'nav.lottos' | translate }} </label>
  </a>
  <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <label>{{ 'nav.badges' | translate }} </label>
  </a>
  <a
    *ifScreenSize="screenType.Desktop"
    class="nav-item"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <label>{{ 'nav.wallet' | translate }} </label>
  </a>
  <a
    *ifScreenSize="screenType.Desktop"
    class="nav-item"
    [routerLink]="['/benefits']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <label>{{ 'nav.benefits' | translate }} </label>
  </a>
</nav>
