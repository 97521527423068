<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar bg-app d-flex align-items-center justify-content-between">
    <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img class="home-logo" src="assets/images/club_logo_filled.svg" draggable="false" />
    </a>
    <ng-container *notInProductCode="productcode.NonRenewing">
      <div ngbDropdown class="nav-item position-static" (openChange)="toggleNotificationCenter($event)" focusTab>
        <button
          class="notification-btn btn p-1"
          id="notificationDropdown"
          attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
          tabindex="-1"
          ngbDropdownToggle
        >
          <span class="icon material-icons text-white material-mobile-nav">notifications</span>
          <ng-container *ngIf="unseenNotificationCount$ | async">
            <div class="count-notification">{{ unseenNotificationCount$ | async }}</div>
          </ng-container>
        </button>

        <div ngbDropdownMenu aria-labelledby="notificationDropdown" class="notification-dropdown shadow dropdown-menu p-0">
          <div class="notification-header d-flex">
            <label class="text-black font-weight-bold font-size-14 pt-3">Notifications</label>
          </div>
          <fgbcl-notification-hub></fgbcl-notification-hub>
        </div>
      </div>
      <a class="nav-item" [routerLink]="['/rewards/events']">
        <span class="icon material-icons font-size-22" role="img" attr.aria-label="{{ 'nav.events' | translate }}"
          >local_activity</span
        >
        <div class="font-size-10 font-prakita-bold-extended">{{ 'nav.events' | translate }}</div>
      </a>
      <a class="nav-item" [routerLink]="['/rewards/wallet']">
        <span class="icon material-icons font-size-22" role="img" attr.aria-label="{{ 'nav.wallet' | translate }}"
          >account_balance_wallet</span
        >
        <div class="font-size-10 font-prakita-bold-extended">{{ 'nav.wallet' | translate }}</div>
      </a>
    </ng-container>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item font-weight-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons font-size-22">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column pt-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon icon-cancel-circle"></span>
      </button>

      <ng-container *notInProductCode="productcode.NonRenewing">
        <div class="mt-2 container">
          <fgb-barcode [isMobileWallet]="true"></fgb-barcode>
        </div>
        <a
          class="nav-item wallet-mobile-nav mt-3"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="block-item wallet-button">
            <span class="icon material-icons mr-2 font-size-22">account_balance_wallet</span>
            <div class="text">{{ 'nav.wallet' | translate }}</div>
          </div>
        </a>
        <div class="block-navigation">
          <a
            class="nav-item"
            [routerLink]="['/rewards/events']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="block-item">
              <span class="icon material-icons mr-2 font-size-22">local_activity</span>
              <div class="text">{{ 'nav.events' | translate }}</div>
            </div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/rewards/marketplace']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="block-item">
              <span class="icon material-icons mr-2 font-size-22">confirmation_number</span>
              <div class="text">{{ 'nav.lottos' | translate }}</div>
            </div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/badges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="block-item">
              <span class="icon material-icons mr-2 font-size-22">local_police</span>
              <div class="text">{{ 'nav.badges' | translate }}</div>
            </div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/benefits']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="block-item">
              <span class="icon material-icons mr-2 font-size-22">star</span>
              <div class="text">{{ 'nav.benefits' | translate }}</div>
            </div>
          </a>
        </div>
        <hr class="my-3" />
      </ng-container>
      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <ng-container *notInProductCode="productcode.NonRenewing">
          <a
            class="nav-item"
            [routerLink]="['/contact']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown mr-2" aria-hidden="true">email</span>
            <div class="nav-text font-weight-bold">{{ 'nav.contact.us' | translate }}</div>
          </a>

          <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-2" aria-hidden="true">article</span>
            <div class="nav-text font-weight-bold">{{ 'nav.terms' | translate }}</div>
          </a>

          <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-2" aria-hidden="true">help</span>
            <div class="nav-text font-weight-bold">{{ 'nav.faq' | translate }}</div>
          </a>
        </ng-container>
        <hr class="mt-5" />
        <a class="nav-item" (click)="logout()">
          <span class="icon material-icons material-mobile-dropdown mr-2" aria-hidden="true">power_settings_new</span>
          <div class="nav-text font-weight-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
