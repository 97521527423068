<div class="container">
  <fgb-barcode></fgb-barcode>
  <div class="mt-lg-2 mt-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
  <div class="mt-lg-5 mt-4 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<div class="container">
  <h2 class="benefits-title mt-3">{{ 'benefits.page.subheading' | translate }}</h2>
  <div class="row">
    <div class="col-lg-4 col-12 pl-0">
      <img class="benefit-image rounded" src="assets/images/benefits/NxtGen.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.nextgen.heading' | translate }}</h5>
        <div class="benefit-tenure py-2">{{ 'benefit.nextgen.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.nextgen.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pl-0">
      <img class="benefit-image rounded" src="assets/images/benefits/AllHeart.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.allheart.heading' | translate }}</h5>
        <div class="benefit-tenure py-2">{{ 'benefit.allheart.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.allheart.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pl-0">
      <img class="benefit-image rounded" src="assets/images/benefits/BelieveMemphis.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.believememphis.heading' | translate }}</h5>
        <div class="benefit-tenure py-2">{{ 'benefit.believememphis.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.believememphis.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pl-0 pt-lg-5">
      <img class="benefit-image rounded" src="assets/images/benefits/GritGrind.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.gritgrind.heading' | translate }}</h5>
        <div class="benefit-tenure py-2">{{ 'benefit.gritgrind.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.gritgrind.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pl-0 pt-lg-5">
      <img class="benefit-image rounded" src="assets/images/benefits/FirstGen.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.firstgen.heading' | translate }}</h5>
        <div class="benefit-tenure py-2">{{ 'benefit.firstgen.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.firstgen.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pl-0 pt-lg-5">
      <img class="benefit-image rounded" src="assets/images/benefits/Premium.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.premium.heading' | translate }}</h5>
        <div class="benefit-tenure py-2">{{ 'benefit.premium.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.premium.description' | translate | markdownTranslate"></div>
      </div>
    </div>
  </div>
</div>
