<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/registration-banner.jpeg" />
  <div class="bg-app d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <div class="content">
      <label class="title">{{ 'login.title' | translate : { fgbdefault: 'WELCOME FANS' } }}</label>
      <div class="panel-text font-size-14">
        {{
          'login.welcome.message'
            | translate : { fgbdefault: 'Log in with your club account below to access your online account.' }
        }}
      </div>
      <fgb-tm-user-login></fgb-tm-user-login>

      <ng-container *ngIf="errorCode$ | async as errorCode">
        <div class="text-white text-center mt-5 pb-5" [innerHTML]="errorCode | translate | markdownTranslate"></div>
      </ng-container>
    </div>
  </div>
</div>
