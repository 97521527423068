<div class="container">
  <fgb-barcode></fgb-barcode>
  <div class="mt-lg-2 mt-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
  <div class="mt-lg-5 mt-4 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<div class="container mt-3">
  <fgbcl-lottos-list [isList]="true"></fgbcl-lottos-list>
</div>
