<div class="card rounded">
  <div *ngIf="member$ | async as member">
    <label class="mb-0 personal-info praktika-extended-font">{{ 'account.details.email' | translate }}</label>
    <div class="d-flex bg-app p-2 m-0 mt-1 rounded">
      <span class="icon material-icons" aria-hidden="true">email</span>
      <span class="ml-3 text-white praktika-medium font-size-14">{{ member.EmailAddress1 }}</span>
    </div>
  </div>
</div>
<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
