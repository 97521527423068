<div class="container mt-3">
  <div class="row">
    <div class="col-12 mb-lg-5 mb-0">
      <fgb-member-card [isAccountPage]="true"></fgb-member-card>

      <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.ecash'">
        <ng-container *ifScreenSize="screenType.Mobile">
          <div class="use-grizzbucks">
            <div class="font-size-14 text-white praktika-extended-font mr-3">{{ 'barcode.use.grizzbuck.text' | translate }}</div>
            <div class="toggle">
              <fgbcl-cash-back-toggle></fgbcl-cash-back-toggle>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-12">
      <h5 class="account-title mt-3 mt-lg-0">{{ 'account.details.title' | translate }}</h5>
      <hr class="bg-white mt-0" />
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>
