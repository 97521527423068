<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/registration-banner.jpeg" />
  <div class="bg-app d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <div class="content">
      <h3
        class="yellow-text text-center my-5"
        [innerHTML]="'maintenancepage.currentlyunavailable.text' | translate | markdownTranslate"
      ></h3>
    </div>
  </div>
</div>
