import { Component, OnInit } from '@angular/core';

import {
  config,
  MemberQuery,
  MarketplaceItemExtended,
  MarketplaceExtendedQuery,
  MarketplaceService,
  ProcessProviderId,
  LottoItem,
  AnnouncementService,
  MemberDetails,
} from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  marketplaceItems$: Observable<MarketplaceItemExtended[] | undefined>;
  rewardMarketplaceItems$: Observable<MarketplaceItemExtended[] | undefined>;
  lottos$: Observable<LottoItem[]>;
  name$: Observable<string>;
  memberDetails$: Observable<MemberDetails | undefined>;
  productcode = ProductCode;
  customOptions: OwlOptions = {
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      740: { items: 1 },
      900: { items: 3 },
    },
  };

  constructor(
    private memberQuery: MemberQuery,
    private marketplaceExtendedQuery: MarketplaceExtendedQuery,
    private marketplaceService: MarketplaceService,
    private announcementService: AnnouncementService
  ) {}

  ngOnInit() {
    this.marketplaceService.fetchMarketplaceItems().toPromise();
    this.getName();
    this.fetchFavouriteEventItems();
    this.fetchFavouriteMarketplaceItems();
    this.announcementService.fetchAnnouncements().toPromise();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }

  fetchFavouriteEventItems() {
    let ProcessProviderId: ProcessProviderId[] = [3];
    this.marketplaceItems$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItemsOfType(...ProcessProviderId).pipe(
      map((items) => {
        return items?.filter((item) => item.Favourite);
      })
    );
  }

  fetchFavouriteMarketplaceItems() {
    let ProcessProviderId: ProcessProviderId[] = [0, 1, 2];
    this.rewardMarketplaceItems$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItemsOfType(...ProcessProviderId).pipe(
      map((items) => {
        return items?.filter((item) => item.Favourite);
      })
    );
  }

  getName() {
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName}`;
        }
        return '';
      })
    );
  }
}
