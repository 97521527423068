import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-user-infomation-modal',
  templateUrl: './user-infomation-modal.component.html',
  styleUrls: ['./user-infomation-modal.component.scss']
})
export class UserInfomationModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
